.sideBar {
  /* background-color: red; */
  padding-right: 0px !important;
  font-size: small;
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  /* background: #00003c; */
}

.sideBarNav {
  margin-top: 4vh;
}

.navItem {
  color: black;
  text-decoration: none;
  display: block;
  padding: 3vh 1vh 3vh 2vh;
  text-align: left;
  /* height: 3rem; */
}

.navItem#activeNav {
  font-weight: 700;
  color: black;
  border-left: #3f50b5 solid 3px;
  /* background-color: #82c2fe; */
}

.navItem#activeNav:hover {
  /* border-left: #82c2fe solid 3px; */
}

.navItem:hover {
  /* color: white; */
  color: black;
  background-color: #82c2fe;
}

/* .navItem a {

  display: inline-block;
  width: 100%;
  height: 100%;
} */

.logoContainer {
  text-align: center;
  /* background-color: #d6d6d5; */
  background: #fed453;

  height: 6rem;
  padding: 0.5rem;
}

.App-logo {
  /* height: 15vh; */
  max-height: 5rem;
  margin: auto;
  /* padding: 10px; */
}

.sideBarLinksDiv {
  font-size: 0.7rem;
  padding: 0px 3px 0px 5px;
}

.navIcons {
  margin-right: 4px;
}
