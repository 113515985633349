.messageBox {
  display: none;
  padding: 10px 0px 0px 12px;
  position: fixed;
  top: 5rem;
  border-radius: 10px;

  border: 2px solid #555555;
  z-index: 110;
  opacity: 0.9;
}

@media screen and (max-width: 700px) {
  .messageBox {
    width: 60%;
    left: 15%;
  }
}

@media screen and (min-width: 700px) and (max-width: 1025px) {
  .messageBox {
    width: 45%;
    left: 30%;
  }
}

@media screen and (min-width: 1026px) {
  .messageBox {
    width: 35%;
    left: 35%;
  }
}

.messageBox.info {
  background-color: rgb(170, 190, 231);
}

.messageBox.success {
  background-color: rgb(143, 242, 143);
}

.messageBox.error {
  background-color: rgb(233, 196, 182);
}

.messageBoxTitle {
  font-size: medium;
  font-style: italic;
  display: inline;
}

.messageBoxCloseBtn.btn-close {
  position: absolute;
  right: 7px;
}

.messageBoxText {
  padding-top: 15px;
}
