.mainPart {
  /* background-color: aqua; */
  padding-left: 0px !important;

  /* position: absolute;
  top: 0px; */
  min-height: 100vh;
}

.contentPart {
  min-height: 87.5vh;

  /* background-color: #e3e3e3; */
  padding: 2rem 1rem 1rem 2rem;
}

.css-1g86id8-MuiTreeItem-content.Mui-selected {
  background-color: transparent !important;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: 700 !important;
}
