.contentReviewerResult {
  background-color: white;
  height: 80vh;
  /* height: auto; */
  width: 100%;
}

.noRowDisplay {
  font-size: 1.5rem;
  text-align: center;
  padding-top: 7%;
}

.dateColorification {
  text-align: center;
  margin: auto 1px;
  padding: 16px 1px;
  width: 100%;
  height: 100%;
}

.dateColorification.yellowBackground {
  background-color: rgb(250, 250, 93);
}

.dateColorification.redBackground {
  color: white;
  background-color: rgb(255, 0, 0);
}
