ul,
.nameTree {
  list-style-type: none;
}

/* Remove margins and padding from the parent ul */
.nameTree {
  margin: 0;
  padding: 0;
  overflow: auto;
}

/* Style the caret/arrow */
.caret {
  font-size: 1rem;
  cursor: pointer;
  font-weight: 400;
  overflow: auto;
}

.noTocElement {
  font-size: 1rem;
  cursor: pointer;
  font-weight: 400;
  overflow: auto;
}

/* Create the caret/arrow with a unicode, and style it */
.caret::before {
  content: "\25B6";
  color: rgb(72, 71, 71);
  display: inline-block;
  margin-right: 6px;
}

/* Rotate the caret/arrow icon when clicked on (using JavaScript) */
.caret-down::before {
  transform: rotate(90deg);
}

/* Hide the nested list */
.nested {
  display: none;
}

/* Show the nested list when the user clicks on the caret/arrow (with JavaScript) */
.active {
  display: block;
}

.taskResult {
  background-color: white;
  height: 80vh;
  /* height: auto; */
  width: 100%;
}

.gridHeader {
  background-color: rgb(246, 246, 246);
  border-left: 1px solid rgb(238, 238, 238);
}

.gridRow {
  /* background-color: blue; */
  /* margin: 2px 0px 2px 0px; */
  /* padding: 2px 0px !important; */
  min-height: 2.5rem !important;
  /* overflow-x: auto !important; */
}

.mainTreeItem div {
  font-size: 0.75rem !important;
  font-weight: 450 !important;
}

.subTreeItem div {
  font-size: 0.75rem !important;
  font-weight: 300 !important;
}

.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
  padding-top: 12px;
}

.MuiDataGrid-footerContainer {
  padding-right: 60px;
}

.firstLayerMainRevisionTreeItem div {
  font-size: 1.1rem !important;
  font-weight: 400 !important;
}

.subLayerMainRevisionTreeItem div {
  /* padding-top: 2px !important; */
  font-size: 0.9rem !important;
  font-weight: 300 !important;
}

.firstLayerPartRevisionTreeItem {
  font-size: 1.1rem !important;
  font-weight: 400 !important;
}

.subLayerPartRevisionTreeItem {
  font-size: 0.9rem !important;
  font-weight: 300 !important;
}

.PartRevisionCheckBox {
  margin: 0 !important;
}
.PartRevisionCheckBox span {
  padding: 0 !important;
}

.noRowDisplay {
  font-size: 1.5rem;
  text-align: center;
  padding-top: 7%;
}

.passedDeadline {
  padding: 5px;
  background-color: rgb(252, 52, 52);
  color: rgb(255, 255, 255);
  border-radius: 6%;
}
.nearDeadline {
  padding: 5px;
  background: rgb(251, 230, 3);
  border-radius: 6%;
}
