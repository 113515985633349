.topNavbar {
  background-color: #f7f7f7;
  height: 6rem;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.topNavText {
  display: inline;
}

.signOutButton {
  display: inline;
  background-color: transparent;
  border-color: transparent;
  color: rgb(62, 62, 224);
  font-size: 1.1rem;
  padding: 0 5px 0 4px;
}
