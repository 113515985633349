.pageRoot {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 699px) {
  .pageRoot {
    display: block;
  }
  .mainPart {
    width: 100vw;
  }
  .sideBar {
    width: 100vw;
  }
}

@media screen and (min-width: 700px) and (max-width: 850px) {
  .mainPart {
    display: inline-block;
    width: 78vw;
  }
  .sideBar {
    display: inline-block;
    width: 22vw;
  }
}

@media screen and (min-width: 851px) and (max-width: 1000px) {
  .mainPart {
    display: inline-block;
    width: 78vw;
  }
  .sideBar {
    display: inline-block;
    width: 22vw;
  }
}

@media screen and (min-width: 1001px) and (max-width: 1225px) {
  .mainPart {
    display: inline-block;
    width: 80vw;
  }
  .sideBar {
    display: inline-block;
    width: 20vw;
  }
}

@media screen and (min-width: 1226px) {
  .mainPart {
    display: inline-block;
    width: 84vw;
  }
  .sideBar {
    display: inline-block;
    width: 16vw;
  }
}
