.validation-box {
  background-color: #e7f3fe;
  color: #0c5460;
  border: 1px solid #b8daff;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 5px;
  font-family: Arial, sans-serif;
  font-size: 16px;
}
.validation-box p {
  margin: 0;
}
