.sideBySideContainer {
  display: grid;
  grid-template-columns: 5fr 1fr;
  margin-top: 30px;
}

.modalDescription {
  font-size: 13px;
  font-weight: 420;
  margin-left: 10px;
}

.accordionButton {
  color: #0d6efd;
  margin-right: 12px;
  font-size: 25px;
  background-color: unset;
  border: 2px;
  padding: 2px 6px 2px 6px;
}

.partRevisionContainer {
  margin-left: 30px;
  margin-top: 10px;
}
