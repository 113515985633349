.tasks-table-container {
  display: inline-block;
  font-size: 0.8rem;
  margin: 1rem 0 0 0rem;
  border-collapse: collapse;
  width: 63vw;
}

.tasks-table {
  width: 100%;
  border-collapse: collapse;
}

.tasks-table th,
.tasks-table td {
  border: 1px solid #ddd;
  padding: 6px 12px;
  text-align: left;
}

.tasks-table th {
  background-color: #f1d51c;
  color: black;
  font-weight: bold;
}

.tasks-table tr:hover {
  background-color: #ddd !important;
  color: black;
}
